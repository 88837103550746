import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import Profile from "../../components/Profile";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../../data/about/chairman/jsondata.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const ChairmanProfile = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a604621f8efa89a3009416"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Chairman's profile" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Chairman's profile"
            />
            <Profile
                image={jsonData.image}
                name={jsonData.title1}
                designation={jsonData.title2}
                title={jsonData.title}
                disc1={jsonData.text}
                disc2={jsonData.text1}
                disc3={jsonData.text2}
            />
        </Layout>
    );
};
ChairmanProfile.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query ChairmanProfileQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default ChairmanProfile;
